<template>
  <div class="d-flex flex-column mt-8">
    <form>
      <div>
        <block-text
          :text="$t('dialog.sendTTUsubtitle')"
          color="secondary"
          type="body-1"
        />
      </div>
      <div class="mt-8">
        <v-text-field
          color="primary"
          :label="$t('dialog.enterAmount')"
          @keypress="allowOnlyDigits($event)"
          v-model="amount"
          :error-messages="fieldErrors('amount')"
        >
          <template v-slot:prepend-inner>
            <v-img
              width="22"
              height="22"
              :src="require('@/assets/ttuToken.svg')"
            />
          </template>
        </v-text-field>
        <wallet-adress
          ref="walletAdress"
          color="primary"
          v-model="walletToID"
          :form-submited="formSubmited"
          required
        ></wallet-adress>
        <v-text-field
          color="primary"
          :label="$t('dialog.enterMessage')"
          v-model="requestNotes"
        ></v-text-field>
      </div>
      <div class="mt-4 d-flex justify-space-between">
        <v-btn
          @click="closeDialog"
          rounded
          :elevation="0"
          text
          :color="'primary'"
          :ripple="false"
          :plain="false"
          class="text-capitalize body-1 px-6 flex-grow-1 font-weight-bold"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="sendTTU"
          outlined
          rounded
          :elevation="0"
          text
          :loading="sendTTULoading"
          :disabled="amount == 0"
          :color="'primary'"
          :ripple="false"
          :plain="false"
          class="text-capitalize body-1 flex-grow-1 font-weight-bold"
          >{{ $t("dialog.sendTTU") }}
          <ph-paper-plane-tilt :size="30" class="pl-3"></ph-paper-plane-tilt>
          <span slot="loader" class="button-loader">
            <v-progress-circular
              :size="24"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </span>
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { PhPaperPlaneTilt } from "phosphor-vue";
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import dateConvertor from "@/mixins/dateConvertor";
import WalletAdress from "@/components/Form/WalletAdress";
import { required } from "vuelidate/lib/validators";

function enoughTTUs(sendAmount) {
  return Number(this.user.balance) > Number(sendAmount);
}

export default {
  components: {
    PhPaperPlaneTilt,
    WalletAdress,
  },
  mixins: [validationMixin, dateConvertor],
  props: {
    passedData: {
      default: null,
    },
  },
  data() {
    return {
      amount: "",
      walletToID: "",
      requestNotes: "",
      formSubmited: false,
    };
  },
  mounted() {
    if (this.passedData && this.passedData.otherWalletId) {
      this.walletToID = this.passedData.otherWalletId;
    }
  },
  validations: {
    amount: { required, enoughTTUs },
    walletToID: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      walletOwner: "wallet/walletOwner",
      error: "wallet/error",
      sendTTULoading: "wallet/sendTTULoading",
    }),
    fieldErrors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        !this.$v[field].enoughTTUs && errors.push("Not enough TTUs.");
        !this.$v[field].required && errors.push("Field is required.");
        return errors;
      };
    },
  },
  methods: {
    ...mapActions({
      sendTTUToWallet: "wallet/sendTTUToWallet",
      getWalletOwner: "wallet/getWalletOwner",
      getUser: "wallet/getUser",
    }),
    closeDialog() {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
    },
    allowOnlyDigits(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async sendTTU() {
      this.formSubmited = true;
      await this.$v.$touch();
      await this.$refs.walletAdress.$v.$touch();

      if (this.$refs.walletAdress.$v.$invalid || this.$v.$invalid) {
        return;
      }

      await this.sendTTUToWallet({
        amount: this.amount,
        walletToID: this.walletToID,
        requestNotes: this.requestNotes,
      });

      if (this.error) {
        this.toggleErrorDialog(this.error);
      } else {
        this.toggleInfoDialog(this.walletToID);
        this.getUser();
        this.$store.dispatch("wallet/getUserActivityLast30Days", {
          filter: "",
          direction: "outgoing",
          skip: 0,
          limit: 4,
        });
      }
    },
    async toggleInfoDialog(walletTo) {
      await this.getWalletOwner(walletTo);
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        headerIcon: "PhCheckCircle",
        dialogPath: "components/Dialogs/TransactionInfoDialog",
        title: this.$t("dialog.transactionCompleted"),
        payload: {
          type: "User Transfer",
          dateTime: new Date().toISOString(),
          status: "Completed",
          transactionHeaderID: uuid.v4(),
          amount: parseFloat(this.amount),
          otherWalletId: this.walletOwner.walletId,
          user: this.walletOwner.fullName,
          userImage: this.walletOwner.profileImageUrl,
          transactionRequestNote: this.requestNotes,
        },
      });
    },
    async toggleErrorDialog(error) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ErrorDialog",
        headerIcon: "PhCircleWavyWarning",
        title: this.$t("dialog.errorTitle"),
        payload: {
          error: error,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-text-field ::v-deep .v-input__prepend-inner {
  padding-right: 6px;
}

.v-text-field ::v-deep input {
  font-weight: 700;
}
</style>
